var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-column justify-center align-center fill-height py-8 overflow-hidden",
    },
    [
      !_vm.allInfoLoaded
        ? _c(
            "v-card",
            [
              _c("v-skeleton-loader", {
                attrs: {
                  type: "card, list-item-two-line, actions",
                  width: "350",
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-card",
                { attrs: { width: "350" } },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "justify-center align-center nuvolos-header",
                    },
                    [
                      _c("v-img", {
                        staticClass: "py-5",
                        attrs: {
                          "max-width": "200",
                          "max-height": "22",
                          src: require("@/assets/Nuvolos-transparent.svg"),
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.validInvGen
                    ? [
                        _c("h2", {
                          staticClass:
                            "secondary--text text-center text-h6 mt-5",
                          domProps: { innerHTML: _vm._s(_vm.cardTitle) },
                        }),
                        _c("v-card-text", [
                          !_vm.$auth.isAuthenticated
                            ? _c(
                                "div",
                                { staticClass: "text-center" },
                                [_c("router-view")],
                                1
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "text-caption mt-6" }, [
                            _vm._v(" By continuing, you agree to Nuvolos's "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://nuvolos.cloud/legal",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("Terms of Service")]
                            ),
                            _vm._v(" and "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://nuvolos.cloud/privacy-policy",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("Privacy Policy")]
                            ),
                            _vm._v(" , and to receiving emails with updates. "),
                          ]),
                        ]),
                      ]
                    : [
                        _c(
                          "v-card-text",
                          { staticClass: "font-weight-bold text-center pt-4" },
                          [
                            _c(
                              "v-alert",
                              {
                                staticClass: "ma-0",
                                attrs: { dense: "", text: "", type: "error" },
                              },
                              [_vm._v(_vm._s(_vm.errorContent))]
                            ),
                          ],
                          1
                        ),
                      ],
                ],
                2
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }